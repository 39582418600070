import { defineStore } from 'pinia'
import { useUserInfo } from './useInfo'
import { getConversationTagsFn } from '@/api/message/conversationTag'
import { getConListFn, getConOnlineInfoFn } from '@/api/message/conversations'

export const useConvInfo = defineStore('convInfo', {
  state: () => ({
    conversationList: [], // 對話列表
    onConversationInfo: new Map(),
    currentChatInfo: {}, // 當前對話[資訊]
    currentChatMsg: [], // 當前對話[內容]
    conversationTags: [],
    rightAsideLoading: false,
    quickReplyMsg: '',
    getConOnlineInfoParams: {
      pageNum: 0,
      pageSize: 20
    },
    queueContent: ''
  }),
  getters: {

  },
  actions: {
    // 獲取對話列表
    async getConListInfo () {
      const res = await getConListFn()

      const data = res.data.value
      if (data.code === 200) {
        this.conversationList = []

        const getOnConvId = localStorage.getItem('onConvId')
        data.data.forEach((i) => {
          const msg = {
            conversationId: i.id, // 對話id
            customerId: i.customerId, // 訪客id
            customerName: i.customerName, // 訪客名稱
            customerAvatar: i.customerAvatar, // 訪客頭像
            channelId: i.channelId, // 渠道id
            channelType: i.channelType,
            tenantId: i.tenantId, // 租戶id
            createdAt: secondsToStr(i.createdAt), // 建立時間
            conversationTags: i.tags, // 對話標籤
            unreadCount: i.unreadCount, // 未讀消息數量
            lastMessage: i.lastMessage, // 最後一句對話
            onConvId: getOnConvId === i.id ? 'true' : 'false', // 判斷對話列表 active
            connectionInfo: i.connectionInfo
          }

          this.onConversationInfo.set(i.id, msg)

          this.conversationList.push(msg)
        })
      }
      return this.conversationList
    },
    // 獲取當前會話內容
    async getCurrentConInfo (conId) {
      const res = await getConOnlineInfoFn(conId, this.getConOnlineInfoParams)
      const data = res.data.value
      if (data.code === 200) {
        const userInfo = useUserInfo()

        let previousGroup = null

        data.data.chatMessageList.data.forEach((i, index) => {
          // 比對客服資料
          const agentInfo = userInfo.agentUsersMap.get(i.agentId)
          i.agentAvatar = agentInfo.avatar
          i.userName = agentInfo.username

          // 是否顯示頭像
          if (i.senderType === 'customer') {
            i.group = 'left'
          } else if (i.senderType === 'system' && i.msgType === 'welcome') {
            i.group = 'center'
          } else {
            i.group = 'right'
          }
          const currentGroup = (i.senderType === 'customer') ? 'customer' : 'others'
          if (index === 1) {
            i.show = true
          } else {
            i.show = (currentGroup !== previousGroup)
          }
          previousGroup = currentGroup
        })

        // 當前對話
        this.currentChatMsg = data.data.chatMessageList.data
      }
    },
    async getConversationTags (params) {
      if (this.conversationTags.length > 0) { return this.conversationTags }
      const param = {
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 10
      }
      const res = await getConversationTagsFn(param)
      const data = res.data.value
      if (data.code === 200) {
        this.conversationTags = data.data.data
      }
      return this.conversationTags
    }
  }
})
